#medium {
    text-align: center;
    padding: 50px 5%;
    background-color: #111; /* Dark background for contrast */
}

.m-title {
    font-size: 40px;
    margin: 0;
    color: #fde100;
    color: #fde100;
    margin-bottom: 25px;
    font-weight: bold;
    text-transform: uppercase;
}
/* POST CONTAINER */
.posts-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 posts per row */
    gap: 30px;
    justify-content: center;
    padding: 20px 5%;
}

.post-card {
    background-color: #1a1a1a;
    color: #fff;
    border-radius: 15px;
    padding: 25px;
    text-align: center;
    box-shadow: 0px 6px 10px rgba(255, 255, 255, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    font-size: 1.1rem;
    line-height: 1.6;
    max-width: 500px;
    margin: auto;
}

    .post-card:hover {
        transform: scale(1.05);
        box-shadow: 0px 8px 16px rgba(255, 255, 255, 0.2);
    }

/* TITLE - Distinctive Look */
.post-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #f8f8f8;
    margin-bottom: 10px;
    text-transform: capitalize;
}

/* DESCRIPTION - Italic & Less Bold */
.post-description {
    font-size: 1rem;
    font-style: italic;
    color: #ccc;
    margin-bottom: 15px;
}

/* READ MORE BUTTON */
.read-more {
    display: inline-block;
    margin-top: 15px;
    color: #fde100;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.1rem;
    transition: color 0.2s ease-in-out;
}

    .read-more:hover {
        text-decoration: underline;
        color: #ff6347;
    }

/* "Decode My Insights" BUTTON */
.button-container {
    text-align: center;
    margin-top: 30px;
}

.decode-btn {
    display: inline-block;
    background: linear-gradient(135deg, #0072b1, #005f8f);
    color: white;
    font-size: 1.3rem;
    padding: 14px 28px;
    border-radius: 10px;
    text-decoration: none;
    font-weight: bold;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

    .decode-btn:hover {
        transform: scale(1.1);
        box-shadow: 0px 8px 16px rgba(255, 255, 255, 0.2);
    }

/* RESPONSIVE DESIGN */
@media (max-width: 900px) {
    .posts-container {
        grid-template-columns: repeat(1, 1fr); /* 1 post per row on smaller screens */
    }
}

@media (max-width: 600px) {
    .post-card {
        padding: 20px;
        font-size: 1rem;
    }

    .decode-btn {
        font-size: 1.1rem;
        padding: 12px 24px;
    }
}
