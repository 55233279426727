.i {
  display: flex;
  height: 100vh;
  flex-wrap: wrap;
}

.i-left, .i-right {
  flex: 1;
  position: relative;
  z-index: 2;
}

.i-left {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
}

.i-right {
  flex: 1;
  position: relative;
  box-sizing: border-box;
}

.i-left-wrapper {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden; /* Ensure the titles do not overflow */
}

/* Intro text */
.i-intro {
  font-size: 40px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fde100;
  margin-bottom: 10px;
}

.i-name {
  font-size: 60px;
  font-weight: bold;
  color: #f2f2f2;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  margin-bottom: 20px;
  text-transform :uppercase;
}

/* Titles in a single line (with mask) */
.i-title {
  display: flex;
  justify-content: center; /* Center titles horizontally */
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}

.i-title-wrapper {
  display: flex;
  flex-direction: row; /* Ensure the titles stay on a single line */
  justify-content: flex-start; /* Keep the titles left-aligned */
  align-items: center; /* Vertically align titles */
  animation: scroll-headlines 15s linear infinite;
  width: auto; /* Do not stretch across the entire container */
  white-space: nowrap; /* Prevent wrapping of items */
}

.i-title-item {
  font-size: 35px;
  font-weight: bold;
  color: #fde100;
  text-transform: uppercase;
  letter-spacing: 3px;
  padding: 0 50px;
  opacity: 0;
  animation: headline-appear 1s ease forwards;
  animation-delay: calc(1s * var(--index)); /* Stagger appearance of each title */
  white-space: nowrap; /* Prevent titles from wrapping */
}

@keyframes headline-appear {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scroll-headlines {
  0% {
    transform: translateX(100%); /* Start from the right */
  }

  100% {
    transform: translateX(-100%); /* End at the left */
  }
}

/* Mask the titles */
.i-title-wrapper {
  -webkit-mask-image: linear-gradient(to right, rgba(0,0,0,1) 30%, rgba(0,0,0,0) 100%);
  mask-image: linear-gradient(to right, rgba(0,0,0,1) 30%, rgba(0,0,0,0) 100%);
}

/* Scroll icon styling */
.i-scroll {
  margin-top: 50px;
  font-size: 50px;
  cursor: pointer;
  animation: bounce 1.5s infinite alternate;
  text-align: center;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(10px);
  }
}

/* Background styling */
.i-bg {
  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
  background-color: #f7e65e;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.6;
}

.i-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
  opacity: 0.3;
}

/* Mobile responsiveness */
@media screen and (max-width: 1024px) {
  .i-left-wrapper {
    padding: 20px;
  }

  .i-name {
    font-size: 50px;
  }

  .i-intro {
    font-size: 35px;
  }

  .i-title-item {
    font-size: 30px;
    letter-spacing: 2px;
  }

  .i-scroll {
    font-size: 30px;
  }
}

/* For tablets */
@media screen and (max-width: 768px) {
  .i-title-wrapper {
    flex-direction: column; /* Stack items vertically on tablets */
  }

  .i-title-item {
    font-size: 28px;
    letter-spacing: 2px;
  }

  .i-scroll {
    font-size: 28px;
  }

  .i-left-wrapper {
    padding: 20px;
    height: 80%; /* Adjust height for smaller screens */
  }

  .i-name {
    font-size: 40px;
  }

  .i-intro {
    font-size: 30px;
  }
}

/* For mobile */
@media screen and (max-width: 480px) {
  .i-title-wrapper {
    flex-direction: column; /* Stack items vertically on mobile */
  }

  .i-title-item {
    font-size: 24px;
    letter-spacing: 1px;
  }

  .i-scroll {
    font-size: 24px;
  }

  .i-left-wrapper {
    padding: 10px;
    height: 100%;
  }

  .i-name {
    font-size: 35px;
  }

  .i-intro {
    font-size: 25px;
  }
}

/* For very small screens */
@media screen and (max-width: 320px) {
  .i-title-wrapper {
    flex-direction: column; /* Stack items vertically on very small screens */
  }

  .i-title-item {
    font-size: 22px;
    letter-spacing: 1px;
  }

  .i-scroll {
    font-size: 22px;
  }

  .i-left-wrapper {
    padding: 10px;
    text-align: center;
  }

  .i-name {
    font-size: 30px;
  }

  .i-intro {
    font-size: 20px;
  }
}
