.a {
    height: 100vh;
    display: flex;
    align-items: center;
}

.a-left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
}

.a-right {
    flex: 1;
    padding: 50px;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* About Card */
.a-card.bg {
    position: absolute;
    top: 50px;
    left: 50px;
    background-color: #ccca3b;
}

.a-card {
    width: 60%;
    height: 70vh;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
}

.a-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Styling for text */
.a-title {
    font-weight: 400;
    margin: 20px 0px;
    color: #fde100;
}

/* Italicizing all text except inside <strong> */
.about-paragraph,
.a-desc {
    font-style: normal;
}

    .about-paragraph strong,
    .a-desc strong {
        /*font-style: italic;*//*
        font-display :revert;*/
    }

/* Social Media */
.social-network {
    margin-top: 25px;
}

    .social-network ul li {
        margin: 0 3px;
    }

        .social-network ul li a {
            width: 80px;
            height: 80px;
            display: inline-block;
            text-align: center;
            line-height: 100px;
            background-color: #2a2a2a;
            color: #ccca3b;
            font-size: 60px;
            transition: all 0.5s;
        }

            .social-network ul li a:hover {
                background-color: #dbd8e3;
                color: #363636;
            }

/* Scroll to Contact Button */
.contact-btn {
    display: block; /* Makes it take full width within its container */
    width: 60%; /* Adjusted size to be slightly more than half */
    max-width: 200px; /* Prevents it from getting too big on large screens */
    margin: 20px auto 0; /* Centers the button */
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1.2rem;
    font-weight: bold;
    text-decoration: none;
    color: white;
    background-color: #ccca3b;
    border-radius: 25px;
    transition: background 0.3s ease;
}

    .contact-btn:hover {
        background-color: #333;
    }

/* Responsive Styling */
@media screen and (max-width: 480px) {
    .a {
        flex-direction: column;
        text-align: center;
        margin-top: 50px;
    }

    .a-left {
        width: 100%;
    }

    .a-card {
        height: 30vh;
    }

        .a-card.bg,
        .a-award {
            display: none;
        }

    .a-right {
        padding: 20px;
    }

    .about-paragraph {
        text-align: justify;
        margin-bottom: 50px;
    }

    .a-desc {
        text-align: center;
        font-size: 1.1rem;
        margin-top: 20px;
    }

    /* Contact button size adjustment for smaller screens */
    .contact-btn {
        font-size: 1rem;
        padding: 8px 16px;
    }
}
