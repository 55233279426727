nav {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent white */
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 999;
}

    nav ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
    }

        nav ul li {
            padding: 10px 20px;
            cursor: pointer;
            color: #333;
            border-right: 1px solid #eee;
            transition: all 0.3s ease;
        }

            nav ul li:last-child {
                border-right: none;
            }

            nav ul li:hover {
                background-color: #f5f5f5;
            }
