.c {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 40px 0; /* Adjust padding so that the height adapts to the content */
}

.c-bg {
    width: 20px;
    height: 100%;
    background-color: #fde100;
    position: absolute;
    left: 0;
}

.c-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    max-width: 800px;
    padding: 40px;
    background-color: #333; /* Dark color for background */
    color: #fafafa; /* Light text color for better contrast */
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    flex-direction: row; /* Default layout - side by side */
    gap: 20px;
}

.c-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex: 1;
}

.c-title {
    font-size: 40px;
    margin: 0;
}

.c-desc {
    font-weight: 300;
    font-size: 18px;
}

.social-network ul {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 0;
    margin: 20px 0;
}

    .social-network ul li {
        list-style: none;
        width: 50px;
        height: 50px;
        background-color: #2a2a2a;
        color: #fde100;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        transition: all 0.3s ease;
    }

        .social-network ul li a {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

            .social-network ul li a:hover {
                background-color: #fde100;
                color: #363636;
            }

/* Medium Screens (768px to 479px) */
@media screen and (max-width: 768px) and (min-width: 480px) {
    .c-wrapper {
        flex-direction: column; /* Stack elements vertically on medium screens */
        width: 90%;
        max-width: 600px;
    }

    .c-title {
        font-size: 30px;
    }

    .social-network ul {
        justify-content: center;
    }
}

/* Small Screens (<= 480px) */
@media screen and (max-width: 480px) {
    .c-title {
        font-size: 24px;
    }

    .social-network ul li a {
        font-size: 18px;
    }

    .c-wrapper {
        width: 90%;
        max-width: 400px;
        flex-direction: column; /* Stack elements vertically on small screens */
    }

    .social-network ul {
        flex-direction: column;
        align-items: center;
    }

        .social-network ul li {
            margin: 10px 0; /* Space between the social icons */
        }
}
