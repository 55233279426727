#youtube {
    text-align: center;
    padding: 40px 5%;
}

.y-title {
    font-size: 40px;
    margin: 0;
    color: #fde100;
    color: #fde100;
    margin-bottom: 25px;
    font-weight: bold;
    text-transform: uppercase;
}

.videos-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 videos per row */
    gap: 20px;
    justify-content: center;
    padding: 20px 5%;
}

.video-card {
    background-color: #222;
    color: #fff;
    border-radius: 12px;
    padding: 15px;
    text-align: center;
    box-shadow: 0px 4px 6px rgba(255, 255, 255, 0.1);
    transition: transform 0.3s ease-in-out;
}

    .video-card:hover {
        transform: scale(1.05);
        box-shadow: 0px 6px 12px rgba(255, 255, 255, 0.2);
    }

/* Make the video height proportional to screen size */
iframe {
    border-radius: 12px;
    width: 100%;
    height: 35vh; /* 35% of the viewport height */
}

/* Stay Tuned Button */
.button-container {
    text-align: center;
    margin-top: 20px;
}

.stay-tuned-btn {
    display: inline-block;
    background: linear-gradient(135deg, #ff416c, #ff4b2b);
    color: #fde100;
    font-size: 1.2rem;
    padding: 12px 24px;
    border-radius: 8px;
    text-decoration: none;
    font-weight: bold;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

    .stay-tuned-btn:hover {
        transform: scale(1.1);
        box-shadow: 0px 6px 12px rgba(255, 255, 255, 0.2);
    }

/* RESPONSIVE LAYOUT */
@media (max-width: 1200px) {
    iframe {
        height: 40vh; /* Slightly taller on large screens */
    }
}

@media (max-width: 900px) {
    .videos-grid {
        grid-template-columns: repeat(1, 1fr); /* 1 video per row on smaller screens */
    }

    iframe {
        height: 50vh; /* Larger height for better visibility on small screens */
    }
}

@media (max-width: 600px) {
    iframe {
        height: 55vh; /* Even taller on small devices */
    }
}
